.default-report-table-wrapper {
  padding-left: 8px !important;
  position: relative;
  z-index: 1;
}

.report-table-wrapper .table-list-rows {
  overflow: visible !important;
}

.report-table-wrapper .table {
  align-items: flex-start;
}

.report-table-wrapper .tbody {
  min-width: 100%;
}

.report-table-wrapper .report-table-header {
  padding-right: 8px;
  position: sticky;
  z-index: 1;
  min-width: 100%;
}

.report-table-wrapper .report-table-header.default-report-table-header {
  top: 68px;
}

.report-table-wrapper .report-table-header.subreport-table-header {
  top: 0px;
}

.report-table-wrapper .report-table-header.subreport-table-header {
  padding-right: 8px;
  position: sticky;
  top: 0px;
}

.report-table-wrapper .report-table-header > .tr {
  z-index: 0;
  background-color: #edf4f7;
  height: 56px;
}

.report-table-wrapper .client-project-report-table-header > .tr {
  background-color: white;
}

.report-table-header .th {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.report-table-header .th.column-center {
  justify-content: center;
}

.report-table-header .th.column-flex-start {
  justify-content: flex-start;
}

.report-table-header .th.column-flex-end {
  justify-content: flex-end;
}

.report-table-wrapper .tr {
  justify-content: space-between;
}

.report-table-tooltip {
  padding: 8px !important;
}

.report-cell {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 16px !important;
  font-weight: 400;
  display: flex;
  word-break: break-word;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;

  p {
    margin-bottom: 0 !important;
  }
}

.report-cell.cell-center {
  justify-content: center;
}

.report-cell.cell-flex-start {
  justify-content: flex-start;
}

.report-cell.cell-flex-end {
  justify-content: flex-end;
}
